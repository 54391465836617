<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="64px"
    height="28px"
    viewBox="0 0 64 28"
    enable-background="new 0 0 64 28"
    xml:space="preserve"
  >
    <title>Tjing logo</title>
    <g />
    <g>
      <g>
        <path
          fill="#FF0064"
          d="M6.6,10v6.3c0,1.6,1.2,1.7,3.4,1.6v3.6c-5.5,0.6-7.4-1-7.4-5.1V10H0V6.2h2.6V3.2L6.6,2v4.3H10V10H6.6z
	M12.9,6.2h3.9v15.5c0,4.5-2.1,6.5-6.6,6.2v-3.8c1.9,0.1,2.7-0.6,2.7-2.4V6.2z M12.4,2.5c0-1.4,1.1-2.5,2.5-2.5
			c1.4,0,2.5,1.2,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5C13.5,5,12.4,3.9,12.4,2.5z M19.3,2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.2,2.5,2.5
			c0,1.4-1.1,2.5-2.5,2.5C20.4,5,19.3,3.9,19.3,2.5z M19.8,6.2h3.9v15.2h-3.9V6.2z M41,12.1v9.3H37v-8.9c0-2.1-1.2-3.1-3-3.1
			c-1.9,0-3.3,1.1-3.3,3.8v8.2h-3.9V6.2h3.9v1.7c0.9-1.4,2.5-2.1,4.5-2.1C38.4,5.8,41,8.1,41,12.1z"
        />
        <linearGradient
          :id="uniqueId(1)"
          gradientUnits="userSpaceOnUse"
          x1="44.7642"
          y1="19.3098"
          x2="64"
          y2="7.400174e-02"
        >
          <stop offset="0.1" style="stop-color: #ff0064" />
          <stop offset="0.8" style="stop-color: #ffb400" />
        </linearGradient>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          :fill="uniqueIdUrl(1)"
          d="M64,0.1l-6,11.4l4.1-1.4c-2.2,4-5,7.7-6.4,9.2
			c-3,3-8,3-11,0c-3-3-3-8,0-11c1.4-1.4,5.2-4.2,9.2-6.4L52.6,6L64,0.1 M47.5,16.6c1.5,1.5,4,1.5,5.5,0s1.5-4,0-5.5
			c-1.5-1.5-4-1.5-5.5,0S46,15,47.5,16.6"
        />
        <linearGradient
          :id="uniqueId(2)"
          gradientUnits="userSpaceOnUse"
          x1="50.149"
          y1="24.8682"
          x2="54.55"
          y2="12.7765"
        >
          <stop offset="7.541770e-04" style="stop-color: #ff0064" />
          <stop offset="1" style="stop-color: #b40068" />
        </linearGradient>
        <path
          :fill="uniqueIdUrl(2)"
          d="M56.4,20c-0.5,0.5-1.1,0.9-1.6,1.3c-0.7,1.8-2.5,3.2-4.5,3.2c-1.6,0-3-0.8-3.9-2l-3.1,1.8
			c1.5,2.3,4.1,3.8,7,3.8c4.7,0,8.4-3.8,8.4-8.4c0-0.7-0.1-1.3-0.2-1.9C57.7,18.7,57,19.5,56.4,20z"
        />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
  data() {
    return {}
  },
  computed: {},
  methods: {
    uniqueId: function (selector) {
      return `SVGID_${selector}_${this._uid}`
    },
    uniqueIdUrl: function (selector) {
      return `url(#SVGID_${selector}_${this._uid})`
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
}
</script>

<style lang="scss" scoped>
svg {
  height: 24px;
  width: 55.83px;
}

.a {
  fill: #ff0064;
}
.b {
  fill-rule: evenodd;
  fill: url(#a);
}
.c {
  fill: url(#b);
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  svg {
    height: 28px;
    width: 64px;
    margin-right: 1.5rem;
  }
}

@media (min-width: 992px) {
}
</style>
