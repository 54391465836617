<template>
  <footer
    :class="{
      spaceForVerificationBanner:
        this.$store.getters.showEmailVerificationBanner,
    }"
  >
    <!--<section class="supporters" v-if="showSupporterLogos">
      <p>Official supporters</p>
      <img
        src="@/assets/img/sponsorimages/Heatland-black-logo.png"
        alt=""
        srcset="@/assets/img/sponsorimages/Heatland-black-logo@2x.png 2x"
      />
    </section>-->
    <div class="footerMainContent">
      <section class="logoWithInfo">
        <Logo />
        <div>
          A competition and practice tool for all disc golfers. Build, manage
          and play any kind of event with live scoring and stats. Experience
          Disc Golf like never before with Tjing.
        </div>
      </section>
      <section class="links">
        <section>
          <h6>Product</h6>
          <ul>
            <li>
              <router-link to="/whytjing">Why Tjing?</router-link>
            </li>
            <li>
              <router-link to="/pricing">Pricing</router-link>
            </li>
            <li>
              <router-link to="/events">Events</router-link>
            </li>
            <li>
              <router-link to="/courses">Courses</router-link>
            </li>
            <li>
              <router-link to="/clubs">Clubs</router-link>
            </li>
            <li>
              <router-link to="/associations">Associations</router-link>
            </li>
            <li>
              <router-link to="/promoters">Promoters</router-link>
            </li>
            <li>
              <a href="https://shop.tjing.se">Shop</a>
            </li>
            <li>
              <a href="https://apps.apple.com/se/app/tjing/id1277060479"
                >Download for iOS</a
              >
            </li>
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.kidpilot.tjing"
                >Download for Android</a
              >
            </li>
          </ul>
        </section>
        <section>
          <h6>Follow</h6>
          <ul>
            <li>
              <router-link to="/gratitude">Gratitude Wall</router-link>
            </li>
            <li>
              <a href="https://www.patreon.com/tjing">Become a Patreon</a>
            </li>
            <li>
              <a href="https://www.facebook.com/tjingscoring/">Facebook</a>
            </li>
            <li>
              <a href="http://instagram.com/tjingapp/">Instagram</a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCQZOxyCry0JPtRYa-i4FogA"
                >YouTube</a
              >
            </li>
          </ul>
        </section>
        <section>
          <h6>Resources</h6>
          <ul>
            <li>
              <router-link to="/faq">FAQ</router-link>
            </li>
            <li>
              <a href="https://support.tjing.se">Tjing Docs</a>
            </li>
            <li>
              <router-link to="/legal">Privacy and Terms</router-link>
            </li>
          </ul>
        </section>
        <section>
          <h6>Company</h6>
          <ul>
            <li>
              <router-link to="/about">About</router-link>
            </li>
            <li>
              <router-link to="/career">Career</router-link>
            </li>
            <li>
              <router-link to="/contact">Contact us</router-link>
            </li>
            <li>
              <a href="https://tjingdg.tumblr.com" target="_blank">Blog</a>
            </li>
          </ul>
        </section>
      </section>
    </div>
    <div class="footerBottom">
      <div class="footerBottomContent">
        <div class="footerBottomContent--left">
          <img src="@/assets/img/sdgf-black.svg" alt="SDGF" />
          <p>Official App of the Swedish Disc Golf Association</p>
        </div>
        <div class="footerBottomContent--middle">
          <img
            src="@/assets/img/PDGA-BLACK-logo.svg"
            class="PDGALogo"
            alt="PDGA"
          />
          <p>Player rating data ©{{ currentYear }} PDGA</p>
        </div>
        <div class="footerBottomContent--right">
          <div class="footerBottomCopyright">
            <p>
              &copy; {{ currentYear }} Tjing. All rights reserved. Various
              trademarks held by their respective owners.
            </p>
            <p>
              Tjing is a registered trademark of Kidpilot AB, registered in the
              EU and in other countries.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from 'moment'
import Logo from '@/components/Logo'
export default {
  components: {
    Logo,
  },
  name: 'TjingFooter',
  computed: {
    currentYear() {
      return moment().format('YYYY')
    },
    showSupporterLogos() {
      let bool = false

      if (this.$route.name == 'events') bool = true

      return bool
    },
  },
}
</script>

<style scoped lang="scss">
.supporters {
  margin-bottom: 48px;
  padding-top: 32px;
  height: 240px;
  background: $mist;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 16px;
    color: $midnight;
    margin: 0;
  }

  img {
    margin-top: 48px;
  }
}
$maxWidthDesktop: 1280px;
footer {
  &.spaceForVerificationBanner {
    margin-bottom: 130px;
  }
  padding-top: 90px;
  padding-bottom: 1px;
  background-color: #fff;
  color: $elephant;
  font-size: 16px;
  @include Gilroy-Regular;
  font-weight: normal;
  font-style: normal;
  width: 100%;
  .logoWithInfo {
    max-width: 30%;
    color: $midnight;
    font-size: 14px;
    svg {
      width: 128px;
      height: auto;
    }
    div {
      margin-top: 14px;
      line-height: 18px;
    }
  }
  .footerMainContent {
    display: flex;
    width: 80%;
    margin: 0 auto 60px auto;
    .links {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      section {
        margin-left: 68px;
      }

      a {
        color: $strom;
      }
    }
    h6 {
      padding: 0;
      color: $midnight;
      margin-bottom: 12px;
      font-size: 16px;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      li {
        margin-bottom: 6px;
        &:hover {
          color: darken($midnight, 50%);
        }
      }
    }
  }
  .footerBottom {
    width: 100%;
    background-color: $lightGray;

    .footerBottomContent {
      width: 80%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 20px 0;
      > div {
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .footerBottomContent--left {
        color: $midnight;
        p {
          margin-bottom: 0;
          margin-left: 22px;
          @include Gilroy-Regular;
          font-size: 16px;
        }
        img {
          width: 60px;
        }
      }
      .footerBottomContent--middle {
        color: $midnight;
        border-right: 1px solid $sleet;
        border-left: 1px solid $sleet;
        p {
          margin-bottom: 0;
          margin-left: 22px;
          @include Gilroy-Regular;
          font-size: 16px;
        }

        .PDGALogo {
          width: 100px;
        }
      }
      .footerBottomContent--right {
        span {
          margin: 0 5px;
        }
      }
    }
    .footerBottomCopyright {
      p {
        margin: 0;
        font-size: 10px;
        line-height: 1.2em;
        color: $midnight;
      }
    }
  }
}
@media (min-width: 992px) {
  footer {
    &.spaceForVerificationBanner {
      margin-bottom: 100px;
    }
  }
}
@media (min-width: 1200px) {
  .supporters {
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 200px;
    padding-top: 0;
    margin-bottom: 64px;
    p {
      margin-right: 90px;
      font-size: 18px;
    }

    img {
      margin: 0;
    }
  }
}

@media (max-width: $maxWidthDesktop) {
  .footerMainContent,
  .footerBottomContent {
    width: 92% !important;
  }
}
@media (max-width: 767px) {
  footer {
    padding-top: 40px;

    .footerBottom {
      .footerBottomContent {
        .footerBottomContent--middle {
          border-right: none;
          border-left: none;
          flex-direction: column;
          margin-bottom: 32px;

          p {
            @include Gilroy-Bold;
            font-size: 12px;
            margin-left: 0;
          }

          .PDGALogo {
            margin-bottom: 18px;
          }
        }
        .footerBottomContent--left {
          margin-bottom: 32px;
          flex-direction: column;

          img {
            margin-bottom: 18px;
          }

          p {
            @include Gilroy-Bold;
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }
  }
  .footerMainContent {
    flex-direction: column;
    margin-bottom: 0px !important;
    width: 85% !important;
    margin: 0 auto;
    .logoWithInfo {
      margin-bottom: 32px;
      max-width: 100%;
      svg {
        width: 64px !important;
      }
    }

    .links {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 0px;
      section {
        margin-left: 0px !important;
        ul {
          margin-bottom: 52px;
          li {
            font-size: 14px;
            margin-bottom: 14px;
          }
        }
      }
    }
  }
  .footerBottomContent {
    grid-template-columns: 1fr !important;
    * {
      text-align: center;
    }
  }

  .footerBottomContent--right {
    justify-content: center !important;
  }
  .footerBottomCopyright {
    text-align: center;
  }
}
</style>
