// Controls for the temporary elements that are closeable and should be saved in local storage
// enitre main state should remain possible to JSON.strngify and save to local storage
// and core data strucutre should match example element below

/*
	{
		name: 'TEST',
		expirationDate: 'ISODATE',
		hideInterval: 'DAYS', // if 0 don't show again
		hidden: 'ISODATE',
	}
*/

import moment from 'moment'
const temporaryCloseableElements = {
  state: {
    favouriteEventNews: {
      expirationDate: '2022-05-01T00:00:00.000Z',
      hideInterval: 0,
      hidden: null,
    },
  },
  actions: {
    updateTemporaryCloseableElement: {
      root: true,
      handler(action, payload) {
        action.commit('updateTemporaryCloseableElement', payload)
        action.commit('saveTemporaryCloseableElements')
      },
    },
    saveTemporaryCloseableElements: {
      root: true,
      handler(action) {
        action.commit('saveTemporaryCloseableElements')
      },
    },
    loadTemporaryCloseableElements: {
      root: true,
      handler(action) {
        action.commit('loadTemporaryCloseableElements')
      },
    },
  },
  mutations: {
    updateTemporaryCloseableElement(state, payload) {
      state[payload.name].hidden = moment().toISOString()
    },
    saveTemporaryCloseableElements(state) {
      localStorage.setItem('temporaryCloseableElements', JSON.stringify(state))
    },
    loadTemporaryCloseableElements(state) {
      if (localStorage.getItem('temporaryCloseableElements')) {
        const savedState = JSON.parse(
          localStorage.getItem('temporaryCloseableElements')
        )

        for (const key in savedState) {
          state[key] = savedState[key]
        }
      }
    },
  },
  getters: {
    tempObjects: (state) => {
      return state
    },
  },
}

export default temporaryCloseableElements
