var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{ref:"popup",staticClass:"popup-container",class:{ shown: _vm.visible },attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.blocking ? null : _vm.close()},"touchmove":function($event){if($event.target !== $event.currentTarget)return null;$event.preventDefault();return _vm.preventDefault.apply(null, arguments)},"touchstart":function($event){if($event.target !== $event.currentTarget)return null;_vm.blocking ? null : _vm.close()},"mousedown":function($event){if($event.target !== $event.currentTarget)return null;_vm.blocking ? null : _vm.close()}}},[_c('div',{staticClass:"content-container",class:{
      shown:
        (_vm.visible && _vm.waitForContentLoad && _vm.contentLoaded) ||
        (_vm.visible && !_vm.waitForContentLoad),
      hidden: !_vm.visible,
    }},[(
        (_vm.showCloseButton && _vm.waitForContentLoad === false) ||
        (_vm.showCloseButton && _vm.waitForContentLoad && _vm.contentLoaded)
      )?_c('div',{staticClass:"close-button"},[_c('span',{on:{"click":function($event){return _vm.close()}}},[_c('CrossMark')],1)]):_vm._e(),_vm._t("default")],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }