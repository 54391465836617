<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g transform="translate(-401 -96)">
      <g class="a" transform="translate(401 96)">
        <rect class="i" width="48" height="48" rx="8" />
        <rect class="j" x="0.5" y="0.5" width="47" height="47" rx="7.5" />
      </g>
      <g transform="translate(102 2)">
        <line class="b" x2="32" transform="translate(306 131)" />
        <line class="c" y2="7.5" transform="translate(329 123)" />
        <line class="c" y2="5.5" transform="translate(329 117)" />
        <rect
          class="d"
          width="10"
          height="3"
          rx="1.5"
          transform="translate(324 123)"
        />
        <path
          class="d"
          d="M-59.585,40.75h-4.83l-1.811-6h8.453Z"
          transform="translate(391 82.25)"
        />
        <path
          class="b"
          d="M-60.5,34.25a1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.5,1.5"
          transform="translate(391 82.25)"
        />
        <g class="e" transform="translate(391 82.25)">
          <path class="i" d="M-75.5,19.75h-4L-81,49.25h7Z" />
          <path
            class="k"
            d="M -78.54955291748047 20.75 L -79.94786071777344 48.25 L -75.05213928222656 48.25 L -76.45044708251953 20.75 L -78.54955291748047 20.75 M -79.5 19.75 L -75.5 19.75 L -74 49.25 L -81 49.25 L -79.5 19.75 Z"
          />
        </g>
        <path
          class="f"
          d="M-80,48.25l5-5.5-4.5-5.5,4-5.5-4-4"
          transform="translate(391 82.25)"
        />
        <path
          class="g"
          d="M-62.917,28.083a1.5,1.5,0,0,0,1.417.95A1.46,1.46,0,0,0-60,27.617,1.46,1.46,0,0,0-61.5,26.2V22.75"
          transform="translate(391 82.25)"
        />
        <path
          class="h"
          d="M-57,22.25v-2l-26,3v4Z"
          transform="translate(391 82.25)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CraneBasketWithBorder',
}
</script>

<style lang="scss" scoped>
.a {
  fill: #fff;
  stroke: #d2d7dc;
}
.b,
.e,
.f,
.g,
.j {
  fill: none;
}
.b,
.c,
.d,
.f,
.g {
  stroke: #2d323c;
}
.b,
.c,
.d,
.e {
  stroke-miterlimit: 10;
}
.c {
  fill: #00e1a5;
}
.d {
  fill: #05b5bc;
}
.f {
  stroke-miterlimit: 1;
}
.g {
  stroke-linejoin: round;
}
.h,
.k {
  fill: #2d323c;
}
.h {
  stroke: rgba(0, 0, 0, 0);
}
.i,
.k {
  stroke: none;
}
</style>
